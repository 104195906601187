const SPACE_DATA = {
		"u6981363":  [{space_js: "//jffpbdmym.9fenmedia.cn/production/o/common/ji/r_ls_bib.js", space_src: "baidujs", space_type: "inters", space_id: "u6981363"}],
		"u6981365":  [{space_js: "//jffpbdmym.9fenmedia.cn/production/qlk_tnud/static/k/common/i.js", space_src: "baidujs", space_type: "native", space_id: "u6981365"}],
		"u6981364":  [{space_js: "//jffpbdmym.9fenmedia.cn/site/p_kj/source/s_mtc_jg.js", space_src: "baidujs", space_type: "native", space_id: "u6981364"}],
		"u6981366":  [{space_js: "//jffpbdmym.9fenmedia.cn/source/rm/static/l-u-ovell.js", space_src: "baidujs", space_type: "native", space_id: "u6981366"}],
		"u6981367":  [{space_js: "//jffpbdmym.9fenmedia.cn/site/sn/production/mv_pw/fm/o.js", space_src: "baidujs", space_type: "native", space_id: "u6981367"}],
		"u6981368":  [{space_js: "//jffpbdmym.9fenmedia.cn/production/t/on/openjs/wqx/resource/g/source/nq.js", space_src: "baidujs", space_type: "inters", space_id: "u6981368"}],
		"u6981370":  [{space_js: "//jffpbdmym.9fenmedia.cn/site/mh_gpjq_z_in.js", space_src: "baidujs", space_type: "native", space_id: "u6981370"}],
		"u6981369":  [{space_js: "//jffpbdmym.9fenmedia.cn/common/upoxr_y_h_o/production/x.js", space_src: "baidujs", space_type: "native", space_id: "u6981369"}],
		"u6981371":  [{space_js: "//jffpbdmym.9fenmedia.cn/source/n/i/openjs/hq-kra/production/jr.js", space_src: "baidujs", space_type: "native", space_id: "u6981371"}],
		"u6981372":  [{space_js: "//jffpbdmym.9fenmedia.cn/common/o_j/static/ir/openjs/lsbky.js", space_src: "baidujs", space_type: "native", space_id: "u6981372"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6981363"],
	Home_native:  SPACE_DATA["u6981365"],
	Home_banner:  SPACE_DATA["u6981364"],
	Detail_inter:  SPACE_DATA["u6981363"],
	Detail_native:  SPACE_DATA["u6981365"],
	Detail_banner:  SPACE_DATA["u6981364"],
	List_native:  SPACE_DATA["u6981365"],
	List_banner:  SPACE_DATA["u6981364"],
	Result_inter:  SPACE_DATA["u6981363"],
	Result_native:  SPACE_DATA["u6981365"],
	Result_banner:  SPACE_DATA["u6981364"],
	Result_native_center1:  SPACE_DATA["u6981366"],
	Result_native_center2:  SPACE_DATA["u6981367"],
}
export const ALL_SPACE_MAP = {
    H5_JFBZ_VO_1 : {
		Home_inter:  SPACE_DATA["u6981363"],
		Home_native:  SPACE_DATA["u6981365"],
		Home_banner:  SPACE_DATA["u6981364"],
		Detail_inter:  SPACE_DATA["u6981363"],
		Detail_native:  SPACE_DATA["u6981365"],
		Detail_banner:  SPACE_DATA["u6981364"],
		List_native:  SPACE_DATA["u6981365"],
		List_banner:  SPACE_DATA["u6981364"],
		Result_inter:  SPACE_DATA["u6981363"],
		Result_native:  SPACE_DATA["u6981365"],
		Result_banner:  SPACE_DATA["u6981364"],
		Result_native_center1:  SPACE_DATA["u6981366"],
		Result_native_center2:  SPACE_DATA["u6981367"],
    },
    H5_JFBZ_VO_2 : {
		Home_inter:  SPACE_DATA["u6981368"],
		Home_native:  SPACE_DATA["u6981370"],
		Home_banner:  SPACE_DATA["u6981369"],
		Detail_inter:  SPACE_DATA["u6981368"],
		Detail_native:  SPACE_DATA["u6981370"],
		Detail_banner:  SPACE_DATA["u6981369"],
		List_native:  SPACE_DATA["u6981370"],
		List_banner:  SPACE_DATA["u6981369"],
		Result_inter:  SPACE_DATA["u6981368"],
		Result_native:  SPACE_DATA["u6981370"],
		Result_banner:  SPACE_DATA["u6981369"],
		Result_native_center1:  SPACE_DATA["u6981371"],
		Result_native_center2:  SPACE_DATA["u6981372"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6981363"],
		Home_native:  SPACE_DATA["u6981365"],
		Home_banner:  SPACE_DATA["u6981364"],
		Detail_inter:  SPACE_DATA["u6981363"],
		Detail_native:  SPACE_DATA["u6981365"],
		Detail_banner:  SPACE_DATA["u6981364"],
		List_native:  SPACE_DATA["u6981365"],
		List_banner:  SPACE_DATA["u6981364"],
		Result_inter:  SPACE_DATA["u6981363"],
		Result_native:  SPACE_DATA["u6981365"],
		Result_banner:  SPACE_DATA["u6981364"],
		Result_native_center1:  SPACE_DATA["u6981366"],
		Result_native_center2:  SPACE_DATA["u6981367"],
    },
};
